import {
  collection,
  DocumentData,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "@firebase/firestore";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button } from "tailwind-mobile/react";
import noAvatar from "../assets/images/no-avatar.png";
import { firestore } from "../service/firebase";
// import { tweetCollection } from "../pages/Home";
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  // tweets: any;
  setting: any;
  totalCount: number;
}
const MAX_TWEETS = 5;
export default function Tweets(props: Props) {
  const { className, totalCount, setting, ...rest } = props;
  return (
    <div className={`${className} `} {...rest}>
      <div className="flex flex-row justify-start">
        <small
          className="twitter-gradient mx-4 w-max rounded-t-md py-1 px-2 text-xs"
          style={{ color: "#19191c", textTransform: "capitalize" }}>
          #{setting?.hashtag}
        </small>
      </div>
      <div
        className="twitter-gradient mx-4 w-80 rounded-lg rounded-tl-none p-2 mb-1 md:w-160"
        style={{ padding: "0.5rem" }}>
        <TweetsList
          setting={setting}
          totalCount={totalCount}
          //  tweets={tweets}
        />
      </div>
    </div>
  );
}

const TweetsList = ({ setting, totalCount }: any) => {
  const tweetCollection = (
    hashtag: string,
    lastVisible?: QueryDocumentSnapshot<DocumentData>
  ) => {
    const startDate = dayjs().startOf("day").toDate();
    const endDate = dayjs().endOf("day").toDate();
    if (Boolean(lastVisible?.id)) {
      return query(
        collection(firestore, `tweets-${setting?.hashtag}`),
        orderBy("created_at", "desc"),
        where("created_at", ">", startDate),
        where("created_at", "<", endDate),
        startAfter(lastVisible),
        limit(setting?.maxInPage || MAX_TWEETS)
      );
    } else {
      return query(
        collection(firestore, `tweets-${setting?.hashtag}`),
        orderBy("created_at", "desc"),
        where("created_at", ">", startDate),
        where("created_at", "<", endDate),
        limit(setting?.maxInPage || MAX_TWEETS)
      );
    }
  };
  const [queryTweets, setQueryTweets] = useState<Query<DocumentData> | null>(
    null
  );

  const [tweets, setTweets] = useState<any>([]);
  const [tweetsDocs, loading] = useCollection(
    queryTweets || tweetCollection(setting?.hashtag)
  );

  const handleOpenTwiter = (twitter_id: number, username: string) => {
    const url = `https://twitter.com/${username}/status/${twitter_id}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (!tweetsDocs?.size && !loading) return;
    let data: any[] = [];
    tweetsDocs?.forEach((doc) => {
      data.push(doc.data());
    });

    setTweets((s: any) => [...s, ...data]);
    // return () => {};
    // eslint-disable-next-line
  }, [loading, tweetsDocs]);

  if (loading && !queryTweets) {
    return (
      <>
        <SkeletonLoading />
        <SkeletonLoading />
      </>
    );
  }
  if (!Boolean(tweetsDocs?.size) && !queryTweets) {
    return <NoTweets />;
  }

  const handleLoadMore = () => {
    setQueryTweets(
      tweetCollection(
        setting?.hashtag,
        tweetsDocs?.docs[tweetsDocs?.docs.length - 1]
      )
    );
  };
  return (
    <>
      {tweets?.map((tweet: any) => {
        const created_at = dayjs(tweet?.created_at?.toDate()).fromNow();
        return (
          <div
            onClick={() => handleOpenTwiter(tweet?.id, tweet?.author.username)}
            key={tweet?.id}
            className="tweet-gradient rounded-md p-4 mb-1 last:mb-0 hover:bg-gray-800">
            <div className="flex flex-row items-center">
              <div
                className="rounded-full w-10 h-10 bg-red-700 mr-2"
                style={{
                  backgroundImage: `url(${
                    tweet?.author?.profile_image_url || noAvatar
                  })`,
                  backgroundColor: "white",
                  backgroundSize: "contain",
                  height: "2.5rem",
                  // marginRight: 8,
                }}
              />
              <span>{tweet?.author?.name}</span>
              <small style={{ color: "#5c5c5c", marginLeft: 3 }}>
                {created_at}
              </small>
            </div>
            <div
              style={{
                direction: "rtl",
                fontSize: "0.85rem",
                fontFamily: "'Noto Sans Arabic', 'Roboto', sans-serif",
                // fontFamily: "'Roboto', sans-serif",
                // fontFamily: "'Roboto', sans-serif",
                fontWeight: "100",
              }}>
              {tweet?.text}
            </div>
          </div>
        );
      })}
      {totalCount}
      {totalCount > tweets?.length && (
        <Button onClick={handleLoadMore}>تحميل</Button>
      )}
    </>
  );
};

const SkeletonLoading = () => (
  <div className="tweet-gradient rounded-md p-4 mb-1 last:mb-0">
    <div className="flex animate-pulse flex-row items-center h-full justify-start space-x-5">
      <div className="w-12 bg-gray-300 h-12 rounded-full "></div>
      <div className="flex flex-col space-y-3">
        <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
        <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
      </div>
    </div>
  </div>
);

const NoTweets = () => (
  <div className="tweet-gradient rounded-md p-4 mb-1 last:mb-0">
    <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
      {/* <div className="w-12 bg-gray-300 h-12 rounded-full "></div> */}
      <div className="flex flex-col space-y-3">
        <div className="h-6 rounded-md">لا توجد تغريدات</div>
        {/* <div className="w-24 bg-gray-300 h-6 rounded-md "></div> */}
      </div>
    </div>
  </div>
);
