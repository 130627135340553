// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAcq8fl_h0H40VWN-bdrBzCEdLJfJTLqyc",
  authDomain: "ricoinfinityesports.firebaseapp.com",
  databaseURL: "https://ricoinfinityesports-default-rtdb.firebaseio.com",
  projectId: "ricoinfinityesports",
  storageBucket: "ricoinfinityesports.appspot.com",
  messagingSenderId: "862328663363",
  appId: "1:862328663363:web:9ccc8048b72506e12bd3f6",
  measurementId: "G-LX5DLVP7YY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
getAnalytics(app);
// Firestore.connectFirestoreEmulator(firestore, "localhost", 5050);
export { firestore };
