import { collection, doc } from "@firebase/firestore";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { App, Page } from "tailwind-mobile/react";
import Home from "./pages/Home";
import { firestore } from "./service/firebase";

function MainApp() {
  const settingDoc = doc(collection(firestore, `setting`), "twitter-hash");
  const [setting, loading] = useDocumentData(settingDoc);

  return (
    <App theme="ios">
      <Page>
        {/* <Navbar title="Twitter HashTag" /> */}
        {!loading && <Home setting={setting} />}
      </Page>
    </App>
  );
}

export default MainApp;
