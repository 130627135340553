import { animated, useSpring, config } from "@react-spring/web";
import React, { useEffect, useRef, useState } from "react";
// import ReactAudioPlayer from "react-audio-player";
import { getNumFromPercentage } from "../utility/Percentage";

interface Props extends React.SVGProps<SVGSVGElement> {
  percentage: number;
  size?: number;
  color?: string;
}

const TCL = 72;
export default function Logo(svgprops: Props) {
  const { percentage, size = 150, color = "#fff", ...props } = svgprops;
  // const audioRef = useRef<ReactAudioPlayer>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [playAudio, setPlayAudio] = useState(false);
  // const percentage = 20;
  const circlePercentage = getNumFromPercentage(percentage, TCL).toFixed(0);

  const [flip, set] = useState(false);
  const { x } = useSpring({
    reset: true,
    reverse: flip,
    from: { x: 78 },
    x: 82,
    // delay: 1000,
    config: config.slow,
    onRest: () => set(!flip),
  });

  const { xPlay } = useSpring({
    reset: true,
    reverse: flip,
    from: { xPlay: 0 },
    xPlay: 1,
    // delay: 1000,
    config: config.slow,
    onRest: () => set(!flip),
  });

  const handleClickPlay = () => {
    setPlayAudio((p) => !p);
    // setFlipRico((s) => !s);
  };
  // useEffect(() => {
  //   if (percentage < 100) {
  //     setInterval(() => setFlipRico((s) => !s), 3000);
  //   }
  //   return () => {};
  // }, []);

  useEffect(() => {
    if (playAudio && percentage >= 100) {
      // audioRef.current?.audioEl.current?.play();
      audioRef.current?.play();
    } else {
      // audioRef.current?.audioEl.current?.pause();
      audioRef.current?.pause();
    }
    // eslint-disable-next-line
  }, [playAudio]);

  return (
    <>
      <svg
        // xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        onClick={handleClickPlay}
        viewBox="0 0 160 160"
        {...props}>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="4" floodColor="#606265" />
        </filter>
        <circle
          fill="#17181b"
          cx="80"
          cy="80"
          r={60}
          stroke={color}
          strokeWidth="1"
          filter="url(#shadow)"
        />
        {/* <animated.line
        x1="0"
        y1="70"
        x2="0"
        y2={x.to((x) => x * 80)}
        stroke="#696a6a"
        // strokeDashoffset={x.to((x) => (1 - x) * 156)}
        // strokeDashoffset={0}
      />
      <line x1="10" stroke="#696a6a" y1="70" x2="10" y2="80" /> */}

        <defs>
          <g id="lineLoading" stroke="#696a6a">
            <line x1="0" y1="70" x2="0" y2="80" />
          </g>
        </defs>
        <g transform={"translate(80 80)"}>
          <use xlinkHref="#lineLoading" />
          {/* <animated.use */}
          {Array.from(Array(TCL).keys()).map((n) => (
            <use
              key={`empLine-${n}`}
              xlinkHref="#lineLoading"
              transform={`rotate(${n * 5})`}
            />
          ))}
        </g>
        <defs>
          <g id="prefix__a" stroke={percentage >= 100 ? "green" : "#1aa2e9"}>
            {/* <line x1="0" y1="70" x2="0" y2="80" /> */}
            <animated.line
              x1="0"
              y1="70"
              x2="0"
              y2={x}
              // stroke="#696a6a"
              // strokeDashoffset={x.to((x) => (1 - x) * 156)}
              // strokeDashoffset={0}
            />
          </g>
        </defs>

        <g transform="translate(80 80)">
          {Array.from(Array(Number(circlePercentage)).keys()).map((n) => (
            <use
              key={`loadedLine-${n}`}
              xlinkHref="#prefix__a"
              transform={`rotate(${n * 5})`}
            />
          ))}
        </g>
        {/* <rect
        x="0"
        y="42"
        // y={fillPercentage}
        transform="scale(2,-2) translate(0,-100)"
        fill="#fff"
        width="160"
        height="35"
      /> */}
        <filter id="shadowAudio">
          <feDropShadow dx="0" dy="0" stdDeviation="20" floodColor="#1aa2e9" />
        </filter>

        {percentage >= 100 && !playAudio && (
          <>
            <g transform="translate(35 30) scale(0.2)" fill="#1aa2e9">
              <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z" />
            </g>
            <animated.g
              transform="translate(35 30) scale(0.2)"
              fill="#1aa2e9"
              opacity={xPlay}
              // filter="url(#shadowAudio)"
            >
              <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z" />
            </animated.g>
          </>
        )}
        {percentage >= 100 && playAudio && (
          // <g
          //   transform="translate(28 30) scale(0.2)"
          //   fill="#1aa2e9"
          //   // filter="url(#shadowLogo)"
          // >
          //   <path d="M224,435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6   C218.6,448,224,442.6,224,435.8z" />
          //   <path d="M371.8,64h-71.6c-6.7,0-12.2,5.4-12.2,12.1v359.7c0,6.7,5.4,12.2,12.2,12.2h71.6c6.7,0,12.2-5.4,12.2-12.2V76.1   C384,69.4,378.6,64,371.8,64z" />
          // </g>
          <text
            x="80"
            y="80"
            fill="#fff"
            fontFamily={`Roboto`}
            textAnchor="middle"
            alignmentBaseline="middle"
            letterSpacing={2}
            fontWeight="bold"
            // fontWeight="100"
            fontSize="25">
            R8xAziz
          </text>
        )}
        <filter id="shadowLogo">
          <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="#1aa2e9" />
        </filter>
      </svg>

      <audio ref={audioRef}>
        <source src="/music/song_06.ogg" type="audio/ogg" />
        <source src="/music/song_06.mp3" type="audio/mpeg" />
        المتصفح لا يدعم تشغيل الملفات الصوتيه
      </audio>

      {/* <ReactAudioPlayer ref={audioRef} src={MP3} /> */}
    </>
  );
}
