import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  percentage: number;
  size?: number;
  color?: string;
}

export default function LogoRico(svgprops: Props) {
  const { percentage, size = 150, ...props } = svgprops;
  // const percentage = 50;

  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      // onClick={handleClickPlay}
      viewBox="0 0 100 100"
      {...props}>
      <filter id="shadowLogo">
        <feDropShadow dx="0" dy="0" stdDeviation="2" floodColor="#1aa2e9" />
      </filter>
      {/* <rect
        x="0"
        y="0"
        width="100"
        height="100"
        stroke="#fff"
        fill="transparent"
      /> */}

      <g>
        <defs>
          <clipPath id="logoClip">
            {/* <path d="M0 0h200v100H0z" /> */}
            <rect
              x="0"
              y="-100"
              transform="scale(1 -1)"
              width="100"
              height={percentage}
            />
          </clipPath>
        </defs>
        {/* <rect
          x="0"
          y="-100"
          transform="scale(1 -1)"
          width="100"
          height={"50"}
        /> */}
        <g
          // transform="translate(45 45) scale(0.9)"
          clipPath={`url(#logoClip)`}
          filter="url(#shadowLogo)">
          <g transform="translate(5 5) scale(1.15)">
            <path
              data-name="Path 5"
              d="M1294.573 528.066v-10.055h-79.244v10.282h64.486l-25.022 25.021a84.654 84.654 0 00-29.181-9.04 86.4 86.4 0 00-10.283-.611v53.591h10.056l31.055-31.054a75.271 75.271 0 0126.914 31.055h11.209a85.678 85.678 0 00-30.71-38.468l30.493-30.493zm-68.961 54.43v-27.851a74.026 74.026 0 0121.511 6.339z"
              transform="translate(-1215.329 -518.011)"
              fill="#1aa2e9"
            />
            <circle
              data-name="Ellipse 1"
              cx={7.977}
              cy={7.977}
              r={7.977}
              transform="translate(63.291 31.645)"
              fill="#1aa2e9"
            />
          </g>
        </g>
        <g
          // transform="translate(45 45) scale(0.9)"
          transform="translate(5 5) scale(1.15)"
          filter="url(#shadowLogo)">
          <path
            data-name="Path 5"
            d="M1294.573 528.066v-10.055h-79.244v10.282h64.486l-25.022 25.021a84.654 84.654 0 00-29.181-9.04 86.4 86.4 0 00-10.283-.611v53.591h10.056l31.055-31.054a75.271 75.271 0 0126.914 31.055h11.209a85.678 85.678 0 00-30.71-38.468l30.493-30.493zm-68.961 54.43v-27.851a74.026 74.026 0 0121.511 6.339z"
            transform="translate(-1215.329 -518.011)"
            stroke="#fff"
            strokeWidth="0.5"
            fill="rgba(255,255,255,0.1)"
          />
          <circle
            data-name="Ellipse 1"
            cx={7.977}
            cy={7.977}
            r={7.977}
            stroke="#fff"
            strokeWidth="0.5"
            transform="translate(63.291 31.645)"
            fill="rgba(255,255,255,0.1)"
          />
        </g>
      </g>
    </svg>
  );
}
