import { useSpring, a } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import LogoMarsol from "./LogoMarsol";
import LogoRico from "./LogoRico";
interface Props {
  percentage: number;
  size: number;
}

export default function LogosTransition(props: Props) {
  const { percentage, size } = props;

  const [flipped, set] = useState(false);

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  useEffect(() => {
    setInterval(() => set((s) => !s), 3000);
    console.log("====================================");
    console.log("start flipping");
    console.log("====================================");
    return () => {};
  }, []);

  return (
    <>
      <a.div
        // className={`${styles.c} ${styles.back}`}
        style={{
          opacity: opacity.to((o) => 1 - o),
          transform,
          position: "absolute",
        }}>
        <LogoMarsol size={size} percentage={percentage} />
      </a.div>
      <a.div
        // className={`${styles.c} ${styles.front}`}
        style={{
          opacity,
          transform,
          position: "absolute",
          rotateX: "180deg",
        }}>
        <LogoRico size={size} percentage={percentage} />
      </a.div>
    </>
  );
}
