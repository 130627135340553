import React from "react";
import { Page } from "tailwind-mobile/react";
import bg from "../assets/images/background.jpg";
import grid from "../assets/images/grid.png";
import light01 from "../assets/images/light01.png";
import { InfinityEsportsSvg } from "../svg/RicoSvg";

export default function Background(props: React.ComponentProps<typeof Page>) {
  const { children, style, ...rest } = props;
  return (
    <>
      <Page
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          ...style,
        }}
        {...rest}>
        <Page
          style={{
            backgroundImage: `url(${light01})`,
            backgroundSize: "cover",
            backgroundColor: "transparent",
            zIndex: 2,
          }}>
          <Page
            style={{
              backgroundImage: `url(${grid})`,
              backgroundSize: "contain",
              backgroundColor: "transparent",
              zIndex: 3,
            }}>
            {children}
          </Page>
        </Page>
        <InfinityEsportsSvg
          color="#fff"
          className="fixed"
          size={200}
          style={{
            bottom: -50,
            zIndex: 1,
            right: -20,
            opacity: 0.02,
          }}
        />
      </Page>
    </>
  );
}
