import React from "react";
import { getNumFromPercentage } from "../utility/Percentage";

interface Props extends React.SVGProps<SVGSVGElement> {
  percentage: number;
  size?: number;
  color?: string;
}

const TCL = 8;
const PD = 18;
export default function Logo(svgprops: Props) {
  const { percentage = 0, size = "100%", ...props } = svgprops;

  const circlePercentage = getNumFromPercentage(percentage, 86).toFixed(0);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 100 30"
      {...props}>
      <g>
        <text
          x="50%"
          width="100"
          y="2"
          fill="#fff"
          fontFamily={`Kufam`}
          color="#d8d8d8"
          textAnchor="middle"
          alignmentBaseline="before-edge"
          letterSpacing={3}
          fontSize="3">
          {percentage >= 100 ? "COMPLETE" : "LOADING"}
        </text>
        <line
          x1="25"
          y1="3"
          x2="8"
          y2="3"
          //
          stroke="#fff"
          opacity="0.3"
          strokeWidth="0.05"
        />
        <line
          x1="8"
          y1="3"
          x2="0"
          y2="7"
          //
          opacity="0.3"
          stroke="#fff"
          strokeWidth="0.05"
        />
        <line
          x1="92"
          y1="3"
          x2="70"
          y2="3"
          //
          opacity="0.3"
          stroke="#fff"
          strokeWidth="0.05"
        />
        <line
          x1="92"
          y1="3"
          x2="100"
          y2="7"
          opacity="0.3"
          //
          stroke="#fff"
          strokeWidth="0.05"
        />
      </g>
      <g>
        <text
          x="2"
          y="13"
          fill="#fff"
          fontFamily={`Roboto`}
          // textAnchor=""
          alignmentBaseline="before-edge"
          // letterSpacing={1}
          fontWeight="100"
          fontSize="3.0">
          {percentage} %
        </text>
        {Array.from(Array(TCL).keys()).map((n) => (
          <rect
            x={PD + 10.5 * n}
            key={`progressEmpty-${n}`}
            y="14"
            fill="#1c2024"
            transform="skewX(-25)"
            width="10"
            height="2"
          />
        ))}
        <defs>
          <clipPath id="clipProgressBar">
            {/* <path d="M0 0h200v100H0z" /> */}
            <rect
              x="18"
              y="10"
              fill="#1aa2e9"
              // transform="skewX(-25)"
              width={circlePercentage}
              height="10"
            />
          </clipPath>
        </defs>

        {Array.from(Array(TCL).keys()).map((n) => (
          <rect
            x={PD + 10.5 * n}
            key={`progressEmpty-${n}`}
            y="13.5"
            clipPath="url(#clipProgressBar)"
            fill="#1aa2e9"
            transform="skewX(-25)"
            width="10"
            height="3"
          />
        ))}
      </g>
    </svg>
  );
}
