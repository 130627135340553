import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  percentage: number;
  size?: number;
  color?: string;
}

export default function LogoMarsol(svgprops: Props) {
  const { percentage, size = 150, ...props } = svgprops;
  // const percentage = 50;

  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      // onClick={handleClickPlay}
      viewBox="0 0 100 100"
      {...props}>
      <filter id="shadowAudio">
        <feDropShadow dx="0" dy="0" stdDeviation="20" floodColor="#1aa2e9" />
      </filter>
      {/* <rect
        x="0"
        y="0"
        width="100"
        height="100"
        stroke="#fff"
        fill="transparent"
      /> */}
      <defs>
        <clipPath id="clipM">
          <rect
            x="0"
            y="-100"
            transform="scale(1 -1)"
            width="100"
            height={percentage}
          />
          {/* {console.log(fillPercentage)} */}
        </clipPath>
      </defs>
      {/* <rect
        x="0"
        y="-100"
        transform="scale(1 -1)"
        width="100"
        height={percentage}
      /> */}

      <g>
        <defs>
          <linearGradient
            id="a"
            y1={0.506}
            x2={1}
            y2={0.506}
            gradientUnits="objectBoundingBox">
            <stop offset={0} stopColor="#f3e500" />
            <stop offset={1} stopColor="#1dcad3" />
          </linearGradient>
        </defs>

        <g clipPath="url(#clipM)">
          <g transform={`scale(0.255) translate(10, 35)`}>
            <path
              data-name="Path 12"
              d="M439.45 494.35h-1.5l-17.4-59.5a51.4 51.4 0 1 0-75.1-45.6 51.116 51.116 0 0 0 21.7 41.9l-75.3 167.7a35.292 35.292 0 0 0-6.5 0l-75.3-167.7a51.407 51.407 0 1 0-81.2-41.9 51.841 51.841 0 0 0 27.4 45.5l-17.9 59.6h-.7a37.044 37.044 0 1 0 19.8 5.7l17.9-59.6a48.105 48.105 0 0 0 16.5-1.1l75.3 167.7a31.7 31.7 0 1 0 42.8 0l75.3-167.7a51.52 51.52 0 0 0 16.2 1.1l17.6 60a37.1 37.1 0 1 0 57.5 31 37.023 37.023 0 0 0-37.1-37.1Zm-301.9 54.3a17.2 17.2 0 1 1 17.2-17.2 17.2 17.2 0 0 1-17.2 17.2Zm74.2-159.4a31.5 31.5 0 1 1-31.5-31.5 31.5 31.5 0 0 1 31.5 31.5Zm88.6 241.3a11.8 11.8 0 1 1-11.8-11.8 11.7 11.7 0 0 1 11.8 11.8Zm128-241.3a31.5 31.5 0 1 1-31.5-31.5 31.5 31.5 0 0 1 31.5 31.5Zm28.3 142.2a17.2 17.2 0 1 1-17.2-17.2 17.2 17.2 0 0 1 17.2 17.2Z"
              transform="translate(-100.55 -337.85)"
              fill="url(#a)"
            />
          </g>
        </g>
        <g transform={`scale(0.255) translate(10, 35)`}>
          <path
            data-name="Path 12"
            d="M439.45 494.35h-1.5l-17.4-59.5a51.4 51.4 0 1 0-75.1-45.6 51.116 51.116 0 0 0 21.7 41.9l-75.3 167.7a35.292 35.292 0 0 0-6.5 0l-75.3-167.7a51.407 51.407 0 1 0-81.2-41.9 51.841 51.841 0 0 0 27.4 45.5l-17.9 59.6h-.7a37.044 37.044 0 1 0 19.8 5.7l17.9-59.6a48.105 48.105 0 0 0 16.5-1.1l75.3 167.7a31.7 31.7 0 1 0 42.8 0l75.3-167.7a51.52 51.52 0 0 0 16.2 1.1l17.6 60a37.1 37.1 0 1 0 57.5 31 37.023 37.023 0 0 0-37.1-37.1Zm-301.9 54.3a17.2 17.2 0 1 1 17.2-17.2 17.2 17.2 0 0 1-17.2 17.2Zm74.2-159.4a31.5 31.5 0 1 1-31.5-31.5 31.5 31.5 0 0 1 31.5 31.5Zm88.6 241.3a11.8 11.8 0 1 1-11.8-11.8 11.7 11.7 0 0 1 11.8 11.8Zm128-241.3a31.5 31.5 0 1 1-31.5-31.5 31.5 31.5 0 0 1 31.5 31.5Zm28.3 142.2a17.2 17.2 0 1 1-17.2-17.2 17.2 17.2 0 0 1 17.2 17.2Z"
            transform="translate(-100.55 -337.85)"
            // fill="transparent"
            stroke="#fff"
            strokeWidth="1"
            fill="rgba(255,255,255,0.1)"
            // fill="url(#a)"
          />
        </g>
      </g>
    </svg>
  );
}
