import * as React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const InfinityEsportsSvg = (svgprops: Props) => {
  const { size = 50, color, ...props } = svgprops;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 79.244 79.243"
      {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1={-0.027}
          y1={1.027}
          x2={1.084}
          y2={-0.084}
          gradientUnits="objectBoundingBox">
          <stop offset={0.03} stopColor="#06437f" />
          <stop offset={0.3} stopColor="#09518c" />
          <stop offset={0.5} stopColor="#1170a5" />
          <stop offset={0.698} stopColor="#1a98c4" />
          <stop offset={1} stopColor="#21b1d8" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={1.476}
          y1={-0.278}
          x2={-0.326}
          y2={1.158}
          xlinkHref="#prefix__a"
        />
      </defs>
      <path
        data-name="Path 5"
        d="M1294.573 528.066v-10.055h-79.244v10.282h64.486l-25.022 25.021a84.654 84.654 0 00-29.181-9.04 86.4 86.4 0 00-10.283-.611v53.591h10.056l31.055-31.054a75.271 75.271 0 0126.914 31.055h11.209a85.678 85.678 0 00-30.71-38.468l30.493-30.493zm-68.961 54.43v-27.851a74.026 74.026 0 0121.511 6.339z"
        transform="translate(-1215.329 -518.011)"
        fill={color || "url(#prefix__a)"}
      />
      <circle
        data-name="Ellipse 1"
        cx={7.977}
        cy={7.977}
        r={7.977}
        transform="translate(63.291 31.645)"
        fill={color || "url(#prefix__b)"}
      />
    </svg>
  );
};
