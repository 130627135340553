import React from "react";
import Background from "../components/Background";
// import Footer from "../components/Footer";
import Logo from "../components/Logo";
import ProgressBar from "../components/ProgressBar";
import Tweets from "../components/Tweets";
import { collection, limit, query, where } from "@firebase/firestore";
import { firestore } from "../service/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import getPercentage from "../utility/Percentage";
import dayjs from "dayjs";
import { Preloader } from "tailwind-mobile/react";
import LogosTransition from "../components/LogosTransition";
// import TestAnimate from "../components/TestAnimate";

const CLASSNAME = "flex justify-center items-center md:w-160";

export default function Home({ setting }: any) {
  const [tweets, loading] = useCollection(
    tweetCollection(setting?.total_count, setting?.hashtag),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const percentage = getPercentage(
    tweets?.size || 0,
    setting?.total_count || 0
  );
  // const percentage = 50;

  return (
    <Background>
      {!setting?.enabled && (
        <div className="flex flex-col justify-center items-center h-full">
          <h1 style={{ fontSize: 24, marginBottom: 20 }}>
            <span style={{ color: "GrayText" }}>[</span> لم يبدأ التحدي{" "}
            <span style={{ color: "GrayText" }}>]</span>
          </h1>
          <div className="flex" style={{ direction: "rtl" }}>
            <div className="px-2" style={{ color: "GrayText" }}>
              #
            </div>
            <div>{setting?.hashtag}</div>
          </div>
          <h2>{setting?.watingMessage}</h2>
        </div>
      )}
      {setting?.enabled && (
        <>
          {loading && (
            <div className="flex justify-center items-center h-full">
              <Preloader colors={{ icon: "" }} />
            </div>
          )}
          {!loading && (
            <div className="flex flex-col items-center">
              <div
                className={`${CLASSNAME} mt-3`}
                style={{ flex: 0.3, position: "relative" }}>
                <Logo percentage={percentage} />
                {/* <div>
            </div>*/}
                {/* <div>
              <LogoRico percentage={percentage} />
            </div>
            <div>
              <LogoMarsol percentage={percentage} />
            </div> */}
                {!(percentage >= 100) && (
                  <LogosTransition size={80} percentage={percentage} />
                )}
              </div>

              <div className={`${CLASSNAME} mt-3 w-full sm:w-160 sticky top-0`}>
                <ProgressBar className="w-full" percentage={percentage} />
              </div>
              <div style={{ flex: 1 }}>
                <Tweets totalCount={tweets?.size || 0} setting={setting} />
              </div>
              {/* <Footer className={CLASSNAME} style={{ flex: 0.1 }} /> */}
            </div>
          )}
        </>
      )}
    </Background>
  );
}
export const tweetCollection = (limitT: number, hashtag: string) => {
  const startDate = dayjs().startOf("day").toDate();
  const endDate = dayjs().endOf("day").toDate();

  return query(
    collection(firestore, `tweets-${hashtag}`),
    // orderBy("created_at", "desc"),
    where("created_at", ">", startDate),
    where("created_at", "<", endDate),
    limit(limitT)
  );
};
